@tailwind base;
@tailwind components;
@tailwind utilities;

@keyframes marquee {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
}

.animate-marquee {
  animation: marquee 20s linear infinite;
}

/* Styles pour les boutons radio */
.radio-group-item[data-state="checked"] {
  background-color: black !important;
  border-color: black !important;
}

/* Suppression du point blanc */
.radio-group-item[data-state="checked"]::after {
  display: none;
}
